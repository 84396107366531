import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"600"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.accept(true)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel(false)}],"click:outside":function($event){return _vm.cancel(false)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Tasdiqlang")]),_c(VCardText,[_vm._v(_vm._s(_vm.text))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"gray","outlined":""},on:{"click":function($event){return _vm.cancel(false)}}},[_vm._v("Bekor qilish")]),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.accept(true)}}},[_vm._v("Tasdiqlash")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }